import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import theme from "./config/theme";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { PostHogProvider } from "posthog-js/react";

const AppContent = () => (
  <HelmetProvider>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ChakraProvider>
  </HelmetProvider>
);

const isProduction = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  return (
    apiUrl?.includes("speedback.com") &&
    !apiUrl?.includes("staging.speedback.com")
  );
};

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);
ReactGA.initialize("G-E9FPMSW28Y");

root.render(
  <React.StrictMode>
    {isProduction() ? (
      <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={{
          api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
          autocapture: true,
          loaded: (posthog) => {
            // Register environment info for better debugging
            posthog.register({
              environment: "production",
              app_version: process.env.REACT_APP_VERSION,
            });
          },
        }}
      >
        <AppContent />
      </PostHogProvider>
    ) : (
      <AppContent />
    )}
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
